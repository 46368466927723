.redRadio.Mui-checked {
  color: red;
  & .MuiSvgIcon-root {
    fill: red;
  }
}

.blueRadio.Mui-checked {
  color: blue;
  & .MuiSvgIcon-root {
    fill: blue;
  }
}

.greenRadio.Mui-checked {
  color: green;
  & .MuiSvgIcon-root {
    fill: green;
  }
}

.yellowRadio.Mui-checked {
  color: yellow;
  & .MuiSvgIcon-root {
    fill: yellow;
  }
}

.selected-image {
  border: 4px solid #000; /* Цвет рамки */
  border-radius: 10px; /* Радиус скругления углов */
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1); /* Тень */
}

h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.image-list {
  height: 120px;  
}

.image-list-item {
  cursor: pointer;
}

.image-preview {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.image-preview.selected-image {
  border-color: #007bff; /* Цвет рамки для выбранной формы */
}
.text-inputs{
  padding-left: 10px;
  padding-right: 10px;
}
.color-radio-group {
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-bottom: 20px;  
  justify-content: center;
}

.color-radio {
  margin-right: 10px;
}

.text-field {
  width: 100%;
  margin-bottom: 10px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.color-circle.selected {
  border: 2px solid #000; /* Граница для выбранного круга */
}

.form-select{
  height: 120px; 
  overflow-x: 'scroll';
  margin-left: 10px; 
  margin-right: 10px;
  white-space: nowrap;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для IE и Edge */
}
.app{
  padding: 0!important;
}
root{
  padding: 0!important;
}