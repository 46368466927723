.order-detail{
    padding: 10px;
    padding-bottom: 300px!important;
}
.inputs-form{
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 15px;    
}
.order-table{
    border-collapse: collapse;
}
.order-table tr td, .order-table thead th, .order-table tfoot th{
    padding: 5px;
    border: 1px solid lightgray;
}
.order-table tfoot th{
    text-align: left;
}
label{   
    margin-top: 5px;
}
.input {    
    min-width: 100%;
    padding: 8px;
    margin-top: 5px;
}
.input-mini{
    min-width: 50%;
}
.radio-inputs{
    padding-top: 6px;
    padding-bottom: 6px;
}
.radio-inputs input{
    margin-right:5px;
}
.delivery-inputs{
    margin-top: 15px;
}
.button{
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;    
}
.button:hover {
    background-color: #0056b3;
  }
button.button:disabled{
    background-color: grey;    
    cursor: not-allowed;
}
.quantity-button {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 5px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;    
    cursor: pointer;
    margin-right: 0px!important;
  }
  .quantity-button-left{
    border-radius: 5px 0 0 5px;
  }
  .quantity-button-right{
    border-radius: 0 5px 5px 0;
  }
  
  .quantity {
    width: 30px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;    
  }

.quantity-container {
    display: flex;
    align-items: center;
    border: none;
  }